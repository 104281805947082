import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby';

const useStyles = makeStyles({
  root: {
    width: '50%',
    maxWidth: 300,
    '@media (max-width:780px)': {
      width: '100%',
    },
  },
  media: {
    height: 140,
  },
  card: {
    width: '100%',
    margin: 10,
    border: 'none',
    height: 230,
  },
  cardContent: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  postTitle: {
    color: '#081d34 !important',
  },
  divider: {
    width: '100%',
  },
});

const NextPost = ({ image, title, to }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Card className={classes.card} variant="outlined" square>
        <Link to={to}>
          <CardMedia className={classes.media} image={image} title={title} />
          <CardContent className={classes.cardContent}>
            <Typography
              className={classes.postTitle}
              gutterBottom
              variant="h6"
              component="h2"
            >
              {title}
            </Typography>
          </CardContent>
        </Link>
      </Card>
      <Divider className={classes.divider} variant="fullWidth" />
    </Box>
  );
};

NextPost.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
};

export default NextPost;
