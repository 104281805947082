import * as React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PostLayout from '../components/PostLayout';
import Seo from '../components/seo';
import Box from '@material-ui/core/Box';
import NextPost from '../components/NextPost';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const useStyles = makeStyles({
  divider: {
    marginTop: '4rem',
    marginBottom: '4rem',
    width: '100%',
  },
  nexPostsContainer: {
    gap: '1.5rem',
    justifyContent: 'space-between',
    '@media (max-width:780px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
});

const SectionWrapperElement = styled.article`
  margin: 10rem 0 2.5rem 0;
  padding: 30px 0;
  color: rgba(41, 41, 41, 1);

  p,
  a {
    letter-spacing: -0.003em;
    line-height: 32px;
    font-size: 20px;
    font-style: normal;
    word-break: break-word;
    font-weight: 400;
  }

  a {
    color: rgba(41, 41, 41, 1);
    text-decoration: underline;
  }

  p {
    margin-top: 2em;
    margin-bottom: -0.46em;
  }

  h2 {
    margin-top: 2.37em;
    letter-spacing: 0;
    line-height: 24px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: -0.31em;
    ont-style: normal;
  }

  h3 {
    margin-block-start: 3.5rem;
  }

  em {
    font-style: italic;
    letter-spacing: -0.003em;
    line-height: 32px;
    font-size: 20px;
    word-break: break-word;
    font-weight: 400;
  }

  ol {
    padding: 0;
    list-style: none;
    list-style-image: none;
    margin: 0;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    word-wrap: break-word;
    word-break: break-word;
    font-weight: 400;

    li {
      list-style-type: decimal;
    }
  }

  li {
    margin-top: 2.14em;
    font-size: 20px;
    padding-left: 0px;
    margin-left: 30px;
    letter-spacing: -0.003em;
    list-style-type: disc;
    line-height: 28px;
    margin-bottom: -0.46em;
    font-weight: 400;
    font-style: normal;
  }

  table {
    width: 100%;
    margin-top: 3rem;
  }
`;

const ContentContainer = styled.div`
  max-width: 692px;
  width: 100%;
`;

const PostTitle = styled.h1`
  letter-spacing: -0.016em;
  line-height: 40px;
  margin-top: 0.17em;
  font-size: 32px;
  margin-bottom: -0.27em;
  font-weight: 700;
  font-style: normal;
  color: rgba(41, 41, 41, 1);
  font-family: Roboto, Helvetica, Arial, sans-serif;
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 1;
`;

const PostingDate = styled.p`
  color: rgb(117, 117, 117);
  font-size: 14px !important;
  line-height: 20px !important;
  margin-top: 0.875rem !important;
  margin-bottom: 1rem !important;
`;

const BlogPostTemplate = ({ data, location }) => {
  const classes = useStyles();
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const { previous, next } = data;

  return (
    <PostLayout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        twitterImage={post.frontmatter.preview}
        pathName={location.pathname}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <ContentContainer>
          <SectionWrapperElement
            className="blog-post"
            itemScope
            itemType="http://schema.org/Article"
          >
            <header>
              <PostTitle itemProp="headline">
                {post.frontmatter.title}
              </PostTitle>
              <PostingDate>{post.frontmatter.date}</PostingDate>
            </header>
            <ThumbnailImage
              src={post.frontmatter.banner}
              alt={post.frontmatter.title + '- Featured Shot'}
            />
            <MDXRenderer>{post.body}</MDXRenderer>
          </SectionWrapperElement>
          <Divider className={classes.divider} variant="fullWidth" />
          <Box
            className={classes.nexPostsContainer}
            component="nav"
            display="flex"
            flexDirection="row"
            marginBottom={5}
            marginTop={5}
          >
            {[previous, next]
              .filter(post => !!post?.frontmatter)
              .map((post, i) => {
                return (
                  <NextPost
                    key={i}
                    title={post.frontmatter.shortTitle ?? post.frontmatter.title}
                    image={post.frontmatter.thumbnail}
                    to={post.fields.slug}
                  />
                );
              })}
          </Box>
        </ContentContainer>
      </Box>
    </PostLayout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        banner
        preview
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        shortTitle
        thumbnail
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        shortTitle
        thumbnail
      }
    }
  }
`;
